import React, { useState } from 'react'
import './Testimonial.css'
import {testimonialsData} from '../../data/testimonialsData'
import rightArrow from '../../assets/rightArrow.png'
import leftArrow from '../../assets/leftArrow.png'
import {motion} from 'framer-motion'

const Testimonial = () => {
  const transition = {type:'spring', duration :3}
    const [select,setselect] = useState(0);
    const tlength = testimonialsData.length;
  return (
    <div className="Testimonials" id='Testimonials'>
        <div className="left-t">
            <span>Testimonials</span>
            <span className='stroke-text'>what they</span>
            <span>say about us</span>
            <motion.span key = {select} initial={{opacity:0,x:100}} animate={{opacity:1,x:0}} exit={{opacity:0,x:-100}} transition={transition} style={{color:"orange"}}>{testimonialsData[select].review}</motion.span>
            <span>{testimonialsData[select].name}</span> - {testimonialsData[select].status}
        </div>
        <div className="right-t">
            <motion.div 
            initial={{opacity:0,x:-100}} 
            whileInView={{opacity:1,x:0}}
            transition={{...transition,duration:2}}> </motion.div>
            <motion.div 
            initial={{opacity:0,x:100}} 
            whileInView={{opacity:1,x:0}}
            transition={{...transition,duration:2}}> </motion.div>
            
            < motion.img src={testimonialsData[select].image} alt="" key = {select} initial={{opacity:0,x:100}} animate={{opacity:1,x:0}} exit={{opacity:0,x:-100}} transition={transition}/>
            <div className="arrows">
            <img  onClick = {()=> select == 0 ? setselect(tlength-1):setselect((prev)=>prev-1)}src={leftArrow} alt="" />
            <img  onClick = {()=> select == tlength-1 ? setselect(0):setselect((prev)=>prev + 1)}src={rightArrow} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Testimonial